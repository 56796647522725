/* save & cancel buttons - centered */
div.esc_col.esc_col-8 {
  display: flex;
  justify-content: center;
  margin: 20px auto 0px;
}

/* Radio tiles in row */
div#person\.addresses
  div.ee_form-row__container.ee_form-row__container--wide.ee_form-row__container--horizontal {
  display: flex;
}

/* distance between radio tiles */
div#person\.addresses .ee_form-row__item-container {
  margin-right: 32px;
}

/* Row labels */
.beneficiary-details__form .ee_form-row__descriptor,
.beneficiary-details__form .ee_form-row__label {
  font-weight: bold;
}

.beneficiary-details__form .ee_form-row__item.ee_form-row__item--span1 {
  margin-bottom: 0;
}

.beneficiary-details__form .birthdate-form__body__dateInput .ee_form-row {
  margin-bottom: 16px;
  margin-left: 0;
  width: 100%;
}

.beneficiary-details__form .birthdate-form__body__errors {
  margin-top: -24px;
}

.beneficiary-details__address-readonly {
  padding-left: 0px !important;
}

.beneficiary-details__percentage-row {
  margin-top: 20px;
}

.beneficiary-details__form .ee_form-row__messages.ee_form-row__messages--wide {
  margin-top: 16px;
}

/* clear redundant margin to better align with other fields (for item) */
.beneficiary-details__relationship-row .ee_form-row__item {
  margin-bottom: 0 !important;
}

/* remove standard padding left of 12px */
.beneficiary-details__form .ee_control-with-hint__hint {
  padding-left: 0 !important;
  margin: 6px auto 0px;
}

.beneficiary-details__form .beneficiary-details__relationship-row .ee_control-with-hint__hint {
  margin-top: 0px;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .beneficiary-details__form .birthdate-form__body__errors {
    max-width: 400px;
  }
}

@media screen and (min-width: 768px) {
  .beneficiary-details__form .birthdate-form__body__errors {
    margin-top: -10px;
    margin-left: 220px;
    padding-left: 30px;
    max-width: 400px;
  }

  .beneficiary-details__address-readonly {
    padding-left: 12px !important;
  }

  .beneficiary-details__form .beneficiary-details__name-row {
    width: 100%;
  }

  .beneficiary-details__form .beneficiary-details__name-row .ee_control-with-hint__hint {
    margin: 6px auto 0px;
  }

  .beneficiary-details__form .beneficiary-details__relationship-row .ee_control-with-hint__hint {
    padding-left: 12px !important;
    margin: -12px 0px 0px 250px;
  }

  /* set proper distance from button to form */
  .beneficiary-details__form .esc_grid__wrapper {
    margin: 0;
  }

  div.esc_col.esc_col-8 {
    margin-top: 36px;
  }

  .beneficiary-details__percentage-row .field-input-row__body__error .ee_control-with-hint__hint {
    margin-top: -16px;
  }
}

@media screen and (min-width: 1024px) {
  .beneficiary-details__form .beneficiary-details__relationship-row .ee_control-with-hint__hint {
    margin: -12px auto 0px;
  }
}
