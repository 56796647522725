.infoText__text {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin: 32px 0px;
    text-align: center;
}

.infoText__hyperlink {
    color: #8e0038;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .infoText__text {
        margin: 48px 0px;
    }
  
  }
