.ee_step-button__wrapper {
  display: flex;
  align-items: center;
  min-width: 152px;
}

.ee_step-button__wrapper .ee_step-button__step {
  line-height: 1;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  min-width: 38px;
  margin-right: 14px;
  border-right: 1px solid;
}

 .ee_step-button__wrapper .ee_step-button__text {
  min-height: 34px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transition-property: inherit;
}

.ee_button:not(.ee_button--disabled):hover .ee_step-button__wrapper .ee_step-button__text {
  color: #FFF;
  background: #71022E;
  border-color: #71022E;
}

@media (min-width: 768px) {
  .ee_step-button__wrapper {
    min-height: 44px;
  }
}