/* Mobile first */
.container__smoker {
  margin: auto 24px;
}

.smoker-form__checkbox {
  display: flex;
  justify-content: center;
  margin: 16px 0 -12px 0;
  font-size: 14px;
}

.smoker-form__checkbox .ee_checkbox {
  align-items: flex-start;
}

.smoker-form__icon {
  height: 32px;
  width: 32px;
}

.container__smoker .popover__text ul {
  margin: 0;
  padding-inline-start: 10px;
}

@media screen and (min-width: 480px) {
  .smoker-form__checkbox {
    font-size: 16px;
  }

  .smoker-form__icon {
    height: 48px;
    width: 48px;
  }
}
