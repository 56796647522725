/* mobile first */
.field-date-input__body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.field-date-input__body__dateInput {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.field-date-input__body__dateInput .ee_form-row.ee_form-row--wide {
  width: 248px;
  margin-left: auto;
  margin-right: auto;
}

.ee_form-row.ee_form-row--wide {
  width: 100%;
}

.field-date-input__body__dateInput .ee_form-row .ee_form-row__descriptor {
  padding: 0px;
}

.field-date-input__errors {
  width: 100%;
  margin-top: -16px;
  margin-bottom: 16px;
  text-align: center;
}

@media (min-width: 768px){
  .field-date-input__body div.ee_form-row__descriptor.ee_form-row__descriptor--wide{
       padding: 0;
   }
}

.field-date-input__body div.ee_form-row__container--horizontal.ee_form-row__container--with-colspans .ee_form-row__item-container{
   padding-right: 0;
}

.field-date-input__body .ee_control-with-hint.ee_is-invalid span.ee_control-with-hint__hint {
   padding-left: 0;
}
