.fee-page__updating-offer-form  {
  margin-top: 2rem !important;
}

.fee-page_download {
  display: flex;
  justify-content: center;
  margin: 42px 0;
}

/* Mobile styling needed, because of EE */
@media screen and (max-width: 912px) {
  .fee-page__updating-offer-form .ee_form-row__item-container {
    max-width: unset !important;
  }
}

@media screen and (min-width: 768px) {
  .fee-page__updating-offer-form .ee_form-row__item-container {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 912px) {
  .fee-page__updating-offer-form {
    margin: 3rem auto 0px auto !important;
    max-width: 570px;
  }

}
