/* mobile resolutions */
.copy-address {
    display: flex;
    align-items: center;
    line-height: 24px;
    cursor: pointer;
    margin: 20px auto;
}

.copy-address__link-icon {
    vertical-align: middle;
}

.copy-address__link-label {
    font-weight: bold;
    margin-left: 15.69px;
}

/* desktop resolutions */
@media screen and (min-width: 768px) {
    .copy-address {
        margin: 32px auto 24px;
    }
}