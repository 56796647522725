.duw-assessment__card {
    display: grid;
    row-gap: 0.5rem;
    padding: 0 !important;
}

.duw-assessment__detail-container {
    padding: 16px 23px;
}

.duw-assessment__detail-container-results {
    display: grid;
    grid-template-columns: auto 2fr;
}

.duw-assessment__detail-container-results p{
    margin-top: 0;
}

.duw-assessment__detail-container-results:last-child p {
    margin-bottom: 0;
}

.duw-assessment__detail-icon{
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

.duw-assessment__detail-container-price{
    padding: 16px 20px;
    border-top: 1px solid #D9D9D9;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

/* mobile first */
.duw-assessment__body {
    margin: auto 24px;   
}
.duw-assessment__body ul {
    padding-left: 15px;   
}

/* Desktop */
@media screen and (min-width: 480px) {
    .duw-assessment__card-container {
        max-width: 716px;
        margin: auto;
    }

    .duw-assessment__detail-container,
    .duw-assessment__detail-container-price {
      padding: 32px 48px;
    }
}
