/* mobile first */
.subscription-options-form__body__radio_tiles {
  width: 100%;
}

.subscription-options-form__body__radio_tiles .info-content__text {
  text-align: left;
}

.subscription-options-form__body__radio_tiles .radio-tile__container {
  align-items: baseline;
}
