/* mobile resolutions */
.container__money-laundering {
  margin: auto 24px;
}

.container__money-laundering--paragraph {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
}

.radio-groups--error {
  color: #e80c26;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
  margin: 16px auto 26px -12px;
  text-align: left;
}

.radio-groups--link {
  margin-bottom: 42px;
  margin-left: -24px;
}

.radio-groups--linkText {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.radio-groups--errorCard {
  margin: 24px auto 36px -12px;
}

.radio-groups--card {
  color: #333;
}

.container__money-laundering .ee_card>.ee_card__legend {
  max-width: 100%;
}
/* desktop resolutions */
@media screen and (min-width: 912px) {
  .container__money-laundering--paragraph {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 48px;
  }

  .radio_groups--container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .radio-groups--tiles {
    width: 568px;
  }

  .radio-groups--errorSection {
    display: flex;
    flex-direction: column;
  }

  .radio-groups--error {
    margin: 24px auto 36px -12px;
    text-align: center;
  }

  .radio-groups--link {
    font-size: 16px;
    line-height: 24px;
    margin: auto;
    margin-bottom: 12px;
    min-width: 172px;
  }
}
