.consent-to-credit-check .intro__headline {
  font-size: 20px;
  line-height: 28px;       
}

.consent-to-credit-check__section-subtitle {
  font-size: 16px;
  line-height: 24px;       
  margin: 0 0 16px;
}

.consent-to-credit-check__section-0 {
  margin-bottom: 0;
}

.consent-to-credit-check__section .ee_checkbox {
  display: inline-flex;
  align-items: flex-start;
}

.consent-to-credit-check__section-0 label.ee_checkbox--regular.ee_checkbox {
  padding: 8px 0 0;
}

.consent-to-credit-check__section-0 span.ee_checkbox__box.ee_checkbox__box--regular {
  width: 18.6px;
  height: 18.6px;
  border-width: 1.4px;
  border-radius: 2px;
  padding-bottom: 0;
}

.consent-to-credit-check__checkbox-label {
  font-size: 14px;
  line-height: 20px;       
  margin: 0;
}

.consent-to-credit-check__error {
  width: 100%;
}

.consent-to-credit-check__error p.error-message {
  text-align: left;
  padding: 0 16px 0 41px;
}

@media screen and (min-width: 912px) { 
  .consent-to-credit-check .intro__headline {
    font-size: 28px;
    line-height: 36px;       
  }

  .consent-to-credit-check__section-subtitle {
    font-size: 18px;
    line-height: 24px;       
    margin: 0 0 24px;
  }

  .consent-to-credit-check__checkbox-label {
    font-size: 16px;
    line-height: 24px;       
  }

}
