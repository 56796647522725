/* mobile first */
.intro {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.intro__headline {
    color: #8e0038;
    font-size: 20px;
    font-family: "Fedra Serif", "FS Me", Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 28px;
    margin: 32px auto;
}

.intro__headline--with__subline {
    margin: 32px auto 16px;
}

.intro__subline {
    color: #333333;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.subline-tooltip-icon {
    justify-content: center;
    margin-top: 8.4px;
}

/* desktop */
@media screen and (min-width: 912px) {
    .intro__headline {
        font-size: 24px;
        line-height: 32px;       
        margin: 48px auto;
    }

    .intro__headline--with__subline {
        margin: 48px auto 24px;
    }

    .intro__subline {
        font-size: 16px;
        line-height: 24px;
    }
}
