.footer__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer__buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px 32px;
  align-items: center;
}

.footer__buttons button {
  width: 100%;
  max-width: 248px;
  min-height: 40px;
}

.footer__form-footer {
  margin-top: 2px;
}

/* desktop */
@media screen and (min-width: 480px) {

  .footer__container {
    margin: 0 auto;
  }

  .footer__buttons {
    gap: 24px;
  }

}