.beneficiary-details__country {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25; 
  }

  .beneficiary-details__country--labelWithIcon {
    display: flex;
    align-items: center;
    align-content: left;
    margin-bottom: -12px;
  }

  .beneficiary-details__country--labelWithIcon-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .beneficiary-details__country--value {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 7px;
  }


  @media screen and (min-width: 768px) {
    .beneficiary-details__country {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 20px; 
      }

    .beneficiary-details__country--labelWithIcon {
        width: 250px;
    }

    .beneficiary-details__country--labelWithIcon-label {
        font-size: 16px;
        line-height: 24px;
    }
  }
