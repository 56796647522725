.error-modal-business-id{
    color: #767676;
    margin-top: 20px;
    text-align: end;
}

.error-modal-secondary-button {
    float: right;
}

.error-modal-anchor {
    text-decoration: none;
    color: inherit;
}
