/* mobile resolutions */
.need-selection__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto 24px;
}

.radio_groups--icon img {
    height: 32px;
    width: 32px;
}

/* higher resolutions */
@media screen and (min-width: 912px) {
    .radio_groups--icon img {
        height: 48px;
        width: 48px;
    }
}
