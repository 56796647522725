/* Mobile first */
.form-template__content {
  margin: 0;
}

.physical-aspect-form__radio-buttons {
  margin-bottom: 32px;
}

.physical-aspect-form__input-wrapper {
  margin-bottom: 16px;
}

.physical-aspect-form__input-container {
  line-height: 20px;
}

.physical-aspect-form__icon {
  height: 32px;
  width: 32px;
}

@media screen and (min-width: 480px) {
  .physical-aspect-form__radio-buttons {
    margin-bottom: 48px;
  }

  .physical-aspect-form__icon {
    height: 48px;
    width: 48px;
  }

  .physical-aspect-form__input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .physical-aspect-form__input-container {
    display: flex;
    margin: 0 auto;
  }

  .physical-aspect-form__input-container .ee_form-row__descriptor.ee_form-row__descriptor--wide {
    width: 111px;
  }
  
  .physical-aspect-form__input-container .ee_form-row__item-container {
    width: 198px;
  }

  .physical-aspect-form__input-container .ee_form-row.ee_form-row--wide {
    display: flex;
    margin: 0px !important;
  }
  
  .physical-aspect-form__input-container .ee_form-row__item.ee_form-row__item--wide.ee_form-row__item--span1 {
    margin: 0px;
  }
}
 