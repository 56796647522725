.consent-to-release-private-data__section:first-child{
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 2rem;
}

.consent-to-release-private-data__body span.ee_control-with-hint label.ee_radio.ee_radio--regular,
.consent-to-release-private-data__body span.ee_control-with-hint span.ee_control-with-hint__hint {
    padding-left: 36px;
}

/* adjust EE */
.consent-to-release-private-data__body .ee_checkbox {
    display: flex !important;
    align-items: flex-start !important;
}

@media screen and (max-width: 480px) {
    .consent-to-release-private-data__body .ee_radio__label {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .consent-to-release-private-data__body .ee_checkbox__label {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .consent-to-release-private-data__body .ee_tooltip-icon__button{
        height: 0 !important
    }
}

/* mobile first */
.consent-to-release-private-data__section-paragraph{
    font-size: 12px;
}

/* Desktop */
@media screen and (min-width: 480px) {
    .consent-to-release-private-data__section-paragraph{
        font-size: 14px;
    }
}

@media screen and (min-width: 912px) {
    .consent-to-release-private-data .intro__headline {
      font-size: 28px;
      line-height: 36px;
    }

    .consent-to-release-private-data__body span.ee_control-with-hint label.ee_radio.ee_radio--regular,
    .consent-to-release-private-data__body span.ee_control-with-hint span.ee_control-with-hint__hint {
        padding-left: 40px;
    }
}


.consent-to-release-private-data__body .intro__headline {
    hyphens: auto;
}
