.property-owner__icon {
  height: 32px;
  width: 32px;
}

@media screen and (min-width: 480px) {
  .property-owner__icon {
    height: 48px;
    width: 48px;
  }
}
