/* optimise spacing between country and city ertically & zip code and country horizontally */
#location .beneficiary-details__address-readonly {
  margin-bottom: 0;
  margin-left: 10px;
}

/* remove redundant padding to enable readability of default text */
#location .ee_form-row__container--horizontal.ee_form-row__container--with-colspans .ee_form-row__item-container {
  padding-right: 0;
}

/* reduce margin between country and zip & city */
.beneficiary-details__address-row .beneficiary-details__address-field.ee_form-row.ee_form-row--wide {
  margin-bottom: 0;
}

/* read-only values aligned with adjacent input fields */
.beneficiary-details__address .ee_input:read-only {
  padding: 12px 0;
}

/* Address rows - set together*/
.beneficiary-details__address-readonly.ee_form-row.ee_form-row--wide,
.beneficiary-details__address-row.ee_form-row.ee_form-row--wide {
  margin-bottom: 8px;
}

/* Row label in mobile to be removed */
.beneficiary-details__address-row .ee_form-row__descriptor span {
  display: none;
}

.beneficiary-details__address .ee_form-row__messages.ee_form-row__messages--wide {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  /* Error messages in desktop */
  .beneficiary-details__address .ee_form-row__messages.ee_form-row__messages--wide {
    padding-left: initial;
  }

  /* Row label in desktop */
  .beneficiary-details__address .ee_form-row__descriptor span {
    display: flex;
  }

  /* Field label in desktop */
  .beneficiary-details__address .ee_form-row__container .ee_form-row__descriptor {
    display: none !important;
  }

  /* reduce space between country and zip code */
  #location [role=group] {
    margin-bottom: -12px;
  }

}
