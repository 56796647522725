/* mobile first */
.beneficiary-status__container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 25px;
    gap: 14px;
    text-align: center;
}

.beneficiary-status__item {
  width: 50%;
}

.beneficiary-status__container b {
  font-size: 22px;
}

@media screen and (min-width: 480px) {
  .beneficiary-status__container {
    flex-wrap: nowrap;
    gap: 14px;
  }

}