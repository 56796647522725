.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 24px;
}

.downloadpdf .ee_step-button__button.ee_button.ee_button--primary.ee_button--size-normal.ee_button--width-fix {
  justify-content: center;
}

.downloadpdf .ee_step-button__button .ee_button__wrapper {
  padding: 0;
}

.downloadpdf .ee_button {
  padding: 0 170px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #AEAEAE;
  border-radius: 8px;
  margin: 32px auto;  
}

.form-row {
  padding: 20px;
}

.form-row:last-of-type {
  background-color: #EFE7ED;
}

.form-row .ee_checkbox__label {
  padding: 0;
  font-size: 14px;
}

.subheader,
.form-row .bold {
  font-size: 16px;
}

.downloadpdf .ee_step-button {
  margin: 24px 0; 
}

.downloadpdf .ee_step-button__button.ee_button--width-fix {
  min-width: 18em;
  padding: 2px 50px;
}

.downloadpdf .ee_button--width-fix:not(.ee_button--text-link, .ee_button--secondary) .ee_button__wrapper {
  min-width: auto !important; /* Not ideal, but only way to override theme style, it seems! */
}

.downloadpdf .text_content_p {
  text-align: center;
  margin: 10px;
}

.downloadpdf .ee_button.ee_button--regular.ee_button--secondary {
  margin: 24px 0;
}

@media (min-width: 768px) {

  .form-wrapper {
    width: 60%;
    margin: 48px auto;  
  }

  .form-row .ee_checkbox__label {
    font-size: 16px;
  }

  .subheader,
  .form-row .bold {
    font-size: 18px;
  }
}
