.field-input-row__body {
    flex: none;
}
.field-input-row__body__error {
    width: 100%;
    margin-top: -16px;
    margin-bottom: 16px;
    text-align: center;
}

.field-input-row__body__error .ee_control-with-hint__hint {
   padding: 0px !important; 
}