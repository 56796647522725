/* mobile resolutions */
.profession-form__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto 24px;
}

.profession-form__body--select-row, .profession-form__body--autocomplete {
    width: 100%;
}

.profession-form__body--select-row {
    margin-bottom: 7px;
}

.profession-form__body--autocomplete {
    margin-bottom: -18px;
}

/* higher resolutions */
@media screen and (min-width: 912px) {     
    .profession-form__body--select-row {
        margin-bottom: -24px;
        width: 568px;
    }
    
    .profession-form__body--autocomplete {
        margin-top: 24px;
        margin-bottom: -24px;
        width: 568px;
    }
}
