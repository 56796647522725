.tab-list {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 2px;
  align-items: flex-end;
}

.tab-button-container {
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  height: fit-content;
  margin: 0;
  cursor: pointer;
  outline: none;
  text-align: center;
  padding: 24px 10px;
  background-color: #e2f6fa;
  min-width: 70px;
  position: relative;
  flex-basis: 234px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: background, color;
}

.tab-button-container:first-child {
  padding-top: 30px;
}

.tab-button-container:nth-child(2) {
  padding-top: 0px;
}

.tab-button-container:nth-child(3) {
  padding-top: 10px;
}

.tab-button-container:nth-child(2) .tab-title {
  padding-top: 48px;
}

.tab-button-container:hover {
  background: #0f94a7;
  color: #fff;
}

.tab-button-container:hover .tab-input {
  border: 1.5px solid #fff;
  background-color: #0f94a7;
}

.tab-button-container--checked {
  background-color: #0f94a7;
  color: #fff;
  cursor: default;
}

.tab-button-container .ee_price--size-small span:first-child,
.tab-button-container .ee_price--size-small span:nth-child(2) {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.tab-button-container .ee_price--size-small span:nth-child(2) {
  margin-right: 0;
}

.tab-button-container .ee_price--size-small span:nth-child(3) {
  font-size: 12px;
  display: block;
}

.tab-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.tab-subtitle {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
}

.tab-price {
  margin-top: 4px;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: 700;
}

.tab-panel {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.tab-content-title {
  padding-top: 32px;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.tab-content-price-head {
  font-size: 16px;
  text-align: center;
}

.tab-content-price {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.tab-content-moreInfo-btn {
  font-size: 16px;
  border: 0;
  background-color: transparent;
  margin-bottom: 12px;
  color: #8e0038;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  font-weight: 700;
}

.fee-page_download .ee_button__wrapper {
  padding: 0 !important;
}

.tab-checkmark::before {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 1px solid black;
}

.tab-checkmark {
  display: inline-block;
}

.tab-highlight {
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  top: -6px;
  border-radius: 4px;
  background-color: #f6cb00;
  padding: 0 11px;
  color: #333;
}

.tab-input {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #e2f6fa;
  /* Not removed via appearance */
  margin: 2px;
  height: 20px;
  width: 20px;
  border: 1.5px solid #737373;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: background, border;
  margin-top: 14px;
}

.tab-input:checked {
  border: 1.5px solid #fff;
  background-color: #0f94a7;
  cursor: default;
}

.tab-input::before {
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.tab-input:checked::before {
  height: 10px;
  width: 10px;
  background-color: #fff;
  transform: scale(1);
}

.tab-benefit-list {
  font-size: 14px;
  line-height: 20px;
  list-style: none;
}

.tab-benefit-list li {
  padding: 12px 0;
}

.tab-benefit-text {
  padding-right: 10px;
}

.tab-benefit-fixed-list li {
  padding-right: 30px;
}

.tab-benefit-list span {
  flex-grow: 1;
}

.tab-benefit-list li div {
  display: flex;
  align-items: center;
}

.tab-benefit-list .ee_icon-checkcoloricon {
  flex-shrink: 0;
  margin-right: 14px;
  align-self: baseline;
}

.tab-benefit-list li:not(:first-child) {
  border-top: 1px solid #d9d9d9;
}

.tab-benefit-list-tooltip-icon button,
.tab-checkbox-tooltip-icon button {
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
}

.tab-content-body {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #d9d9d9;
}

.overview-button {
  margin-top: 2em;
  margin-bottom: 32px;
}

.ee_tooltip-wrapper.tab-benefit-list-tooltip-icon {
  align-self: flex-start;
}

.tab-content-checkbox-header {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.tab-content-checkbox-subtext {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
}

.tab-content-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-content-checkboxes {
  display: flex;
  flex-direction: column;
  padding: 0 21px 32px 21px;
}

@media screen and (min-width: 442px) {
  .tab-button-container:first-child {
    padding-top: 40px;
  }

  .tab-button-container:nth-child(2) {
    padding-top: 0px;
  }

  .tab-button-container:nth-child(3) {
    padding-top: 20px;
  }
}

@media screen and (min-width: 694px) {
  .tab-content {
    margin-top: -53px;
    padding-top: 4rem;
  }
}

@media screen and (min-width: 912px) {
  .tab-container {
    margin: auto;
    min-width: 864px;
  }

  .tab-title {
    font-size: 18px;
  }

  .tab-subtitle {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }

  .tab-highlight {
    font-size: 12px;
    top: -6px;
    border-radius: 8px;
  }

  .tab-content-title {
    font-size: 18px;
    text-align: center;
  }

  .tab-content-price {
    margin-top: 12px;
    margin-bottom: 14px;
  }

  .tab-content-moreInfo-btn {
    margin-bottom: 45px;
  }

  .tab-content-body {
    align-items: center;
  }

  .tab-benefit-list {
    /* maximum size of tab component */
    width: 604px;
    font-size: 16px;
    line-height: 24px;
  }

  .tab-content-checkboxes {
    width: 604px;
    margin: auto;
  }

  .tab-button-container:first-child {
    padding-top: 42px;
  }

  .tab-button-container:nth-child(2) {
    padding-top: 0px;
  }

  .tab-button-container:nth-child(3) {
    padding-top: 20px;
  }

  div.tab-content-checkbox:nth-child(3) {
    border-top: 1px solid #d9d9d9;
  }

  .tab-content-checkbox-header {
    font-size: 16px;
    line-height: 24px;
  }

  .tab-content-checkbox-subtext {
    font-size: 14px;
    line-height: 20px;
  }

  .overview-button {
    margin-bottom: 48px;
  }

  .tab-button-container .ee_price--size-medium span:first-child,
  .tab-button-container .ee_price--size-medium span:nth-child(2) {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
  }

  .tab-button-container .ee_price--size-medium span:nth-child(2) {
    margin-right: 8px;
  }
}
