/* mobile first */
.status-ribbon__sticky-box {
  position: sticky;
  transform: translate(calc((100vw - 100%) / -2));
  z-index: 999;
}

.status-ribbon {
  width: 100vw;
}
