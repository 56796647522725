/* mobile first */
.after-sales-feedback__hero {
  background-color: #e3f0e6;
  text-align: center;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  padding-top: 32px;
  padding-bottom: 32px;
}

.after-sales-feedback__hero-title {
  font-family: "Fedra Serif", "FS Me", Arial, Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  color: #8e0038;
  hyphens: none;
  font-size: 24px;
  line-height: 32px;
  padding: 1rem 4rem;
}

.after-sales-feedback__hero-subtitle {
  font-size: 14px;
  line-height: 20px;
  max-width: 240px;
  margin: auto;
}

.after-sales-feedback__body {
  display: grid;
  justify-content: center;
  margin-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
  grid-template-columns: none;
  row-gap: 32px;
}

.after-sales-feedback__paragraph1 {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.after-sales-feedback__button-wrapper {
  text-align: center;
}

.after-sales-feedback__fragment-container {
  display: flex;
  margin: 0 24px 24px 24px;
  justify-content: center;
}

/* Desktop */
@media screen and (min-width: 912px) {
  .after-sales-feedback__hero {
    background-color: #e3f0e6;
    text-align: center;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .after-sales-feedback__hero-title {
    font-family: "Fedra Serif", "FS Me", Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    color: #8e0038;
    hyphens: none;
    font-size: 40px;
    line-height: 52px;
  }

  .after-sales-feedback__hero-subtitle {
    font-size: 18px;
    line-height: 24px;
    max-width: none;
    margin: none;
  }

  .after-sales-feedback__body {
    display: grid;
    justify-content: center;
    grid-template-columns: 864px;
    margin-top: 64px;
    row-gap: 64px;
  }

  .after-sales-feedback__paragraph1 {
    line-height: 24px;
    text-align: center;
    font-size: 16px;
  }
}
