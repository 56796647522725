.slider__container {
  max-width: 568px;
  margin: 0 auto;
}

.slider__input-text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 8px;
}

.slider__bar {
  margin: 0 12px;
}

.slider__footer {
  display: flex;
  justify-content: space-between;
}

.slider__edge-text {
  color: #737373;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 18px;
  text-align: right;
}
