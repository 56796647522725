/* ! Please regulary adjust the styles to the latest https://dospa.dvit.ergo.de/de/Produkte/rlv-otr-test styles ! */
.aem__esc_container {
    max-width: 1440px;
    margin: 0 auto;
    font-size: 16px;
    font-family: FS Me, Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: 1.5;
    color: #333333;
}

#dt_content_wrapper {
    clear: both;
}

#dt_content {
    width: 100%;
    max-width: 980px;
    margin: auto;
}

.aem__full-grid {
    max-width: 912px;
    margin: auto;
    box-sizing: border-box;
    border-left: solid transparent;
    border-right: solid transparent;
    border-width: 24px;
}

.aem__GridColumn {
    display: block;
    width: 100%;
    float: left;
    clear: none;
          hyphens: auto;
  }

.aem__fab-container{
    position:fixed;
    bottom:50px;
    right:50px;
    cursor:pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aem__fab{
    border-radius: 15px;
    height: 60px;
    width: 60px;
}
.aem__fab:hover{
    opacity: 1 !important;
}

