/* mobile first */
.infoText {
  color: #333333;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: center;
}

.infoSection * {
  font-size: 12px;
}

.infoSection ul {
  padding-left: 20px;
}

.infoSection ul li {
  margin-bottom: 9px;
  line-height: 18px;
}


.motorbike-form__body {
  margin: auto 24px;
}

.motorbike-form__icon {
  height: 32px;
  width: 32px;
}

.infoSection p {
  margin-top: 40px;
}

.radio_groups--icon {
  height: 40px;
  width: 40px;
}

@media screen and (min-width: 480px) {
  .motorbike-form__icon {
    height: 48px;
    width: 48px;
  }

  .infoSection * {
    font-size: 14px;
  }

  .infoSection ul {
    padding-left: 15px;
  }

  .infoSection ul li {
    margin-bottom: 0;
    line-height: 20px;
  }
  
}
