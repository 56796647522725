/* mobile first */
.personal-data-page {
    margin: 76px 24px auto;
}

/* desktop */
@media screen and (min-width: 912px) { 
    .personal-data-page {
        margin: 96px 24px auto;
    }
}
