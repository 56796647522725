/* mobile resolutions */
.beneficiary__separator {
    height: 1px;
    width: 100%;
    color: #d9d9d9;
    margin: 32px auto;
}

.beneficiary__container {
    display: flex;
    flex-direction: column;
}

.beneficiary__header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
}

.beneficiary__personalInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.beneficiary__personalInfo--name {
    font-weight: bold;
}

.beneficiary__center {
    margin-top: 8px;
}

.beneficiary__address {
    display: flex;
    flex-direction: column;
}

.beneficiary__statistics span {
    align-self: baseline;
}

.beneficiary__statistics {
    display: flex;
    float: left;
    margin-bottom: 16px;
    margin-top: 8px;
}

.beneficiary__statistics--percentage {
    font-size: 20px;
    font-weight: bold;
    margin-left: 9px;
}

.beneficiary__buttons {
    display: flex;
}

.beneficiary__addButton {
    display: flex;
    justify-content: center;
    margin: 32px auto;
}

.beneficiary__buttons--delete .ee_button__wrapper {
    padding-left: 0 !important;
}

.beneficiary__error--container {
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
}

.beneficiary__error--message {
    text-align: left ;
    margin: auto;
    color: #BF1528;
}

/* higher resolutions */
@media screen and (min-width: 912px) {
    .beneficiary__separator {
        margin: 48px auto;
    }

    .beneficiary__top {
        display: flex;
        justify-content: space-between;
    }

    .beneficiary__personalInfo {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 16px;
    }

    .beneficiary__personalInfo--name {
        font-weight: bold;
        padding-right: 16px;
    }

    .beneficiary__center {
        display: flex;
        justify-content: space-between;
    }

    .beneficiary__statistics {
        align-self: flex-end;
        margin-top: 0;
        margin-bottom: 0;
    }

    .beneficiary__statistics--percentage {
        font-size: 24px;
        line-height: 32px;
        margin-left: 12px;
    }

    .beneficiary__addButton .ee_button__content {
        font-size: 16px !important;
    }

    .beneficiary__addButton {
        margin: 48px auto;
    } 
}
