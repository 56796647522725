/* mobile first */
.radio-tile__container {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid;
  border-color: #aeaeae;
  border-radius: 8px;
  padding: 16.5px 20.5px;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, border-color;
}

.radio-tile__container--variable-width {
  /* 16px is the 'gap' property in the container */
  flex-basis: calc((100% - 16px) / 2);
}

.radio-tile__container:hover:not(.radio-tile__container--checked) {
  border-color: transparent;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.16), 0 6px 10px 0 rgba(0, 0, 0, 0.12),
    0 1px 18px 0 rgba(0, 0, 0, 0.1);
}

.radio-tile__container:hover:not(.radio-tile__container--checked) .radio-tile__input {
  box-shadow: 0px 0px 0px 8px rgba(142, 0, 56, 0.08), 0px 0px 0px 12px rgba(142, 0, 56, 0.08) inset;
  border: 1.5px solid #8e0038;
}

.radio-tile__container:hover.radio-tile__container--unchecked__disabled .radio-tile__input {
  box-shadow: none;
  border: 1.5px solid grey;
}

.radio-tile__container.radio-tile__container--checked {
  border: 1.5px solid #8e0038;
  background-color: rgba(142, 0, 56, 0.08);
  padding: 16px 20px;
}

.radio-tile__container.radio-tile__container--inline-display {
  flex-direction: row-reverse;
  justify-content: flex-end;
  height: auto;
  padding: 16.5px 20.5px;
}

.radio-tile__container.radio-tile__container--checked.radio-tile__container--inline-display {
  padding: 16px 20px;
}

.radio-tile__container.radio-tile__container--inline-display .radio-tile__input {
  margin-right: 12px;
}

.radio-tile__container.radio-tile__container--inline-display .info-content__icon {
  margin-bottom: 0;
}

.radio-tile__container:not(.radio-tile__container--inline-display) .info-content__text {
  padding: 1em;
}

.radio-tile__container.radio-tile__container--checked__disabled {
  border: 1.5px solid #f7f0f0;
  background-color: rgba(142, 0, 56, 0.08);
  border-radius: 8px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
  cursor: not-allowed;
}

.radio-tile__container.radio-tile__container--unchecked__disabled {
  background-color: #ffffff;
  cursor: not-allowed;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-content__text {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #333333;
}

.info-content__text.info-content__text--disabled {
  color: grey;
}

.info-content__icon {
  margin: 0 12px;
}

.info-content__icon.info-content__icon--disabled {
  color: grey;
}

.info-content__text__title {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.info-content__text__title-bold {
  font-weight: bold;
}

.info-content__text__subtitle {
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
  margin-top: 8px;
}

.radio-tile__container.radio-tile__container--inline-display .info-content {
  flex-direction: row;
  align-items: center;
  flex-basis: 80%;
}

.radio-tile__input {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  height: 20px;
  width: 20px;
  border: 1.5px solid #737373;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, border-color;
}

.radio-tile__input--disabled {
  cursor: not-allowed;
}

.radio-tile__input:checked {
  border: 1.5px solid #8e0038;
}

.radio-tile__input--disabled:checked {
  border: 1.5px solid grey;
  cursor: not-allowed;
}

.radio-tile__input::before {
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.radio-tile__input:checked::before {
  height: 10px;
  width: 10px;
  background-color: #8e0038;
  transform: scale(1);
}

.radio-tile__input--disabled:checked::before {
  background-color: grey;
}

/* desktop */
@media screen and (min-width: 480px) {
  .radio-tile__container.radio-tile__container--inline-display {
    width: 420px;
    padding: 20.5px 24.5px;
  }

  .radio-tile__container.radio-tile__container--checked.radio-tile__container--inline-display {
    padding: 20px 24px;
  }

  .radio-tile__container--variable-width {
    flex-basis: 272px;
  }

  .info-content__text__title {
    font-size: 16px;
    line-height: 24px;
  }

  .info-content__text__subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}
