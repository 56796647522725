/* Mobile first */
.summary__accordion-wrapper {
  border-top: 1px solid #d9d9d9;
}

.beneficiary__divider {
  width: 30%;
  color: #e1e1e1;
}

.summary__content-wrapper .infoText__text {
  text-align: left;
  display: flex;
  font-size: 10px;
}

.summary__content-wrapper .infoText__text::before { 
  content: '*';
  margin-right: 4px;
}

@media screen and (min-width: 480px) { 
  .summary__content-wrapper .infoText__text {
    font-size: 12px;
  }
  

  .summary__content-wrapper .infoText__text::before { 
    margin-right: 8px;
  } 
}
