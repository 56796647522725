/* mobile first */
.tariff-info__card-title {
    text-align: center;
    margin: 0;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
}
  
.tariff-info__card-content {
    display: grid;
    row-gap: 1rem;
    font-size: 16px;
}
  
.tariff-info__card-content div:nth-child(2) > p {
    display: flex;
    flex-flow: column;
    line-height: 24px;
}

.tariff-info__card-content-param {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 0rem;
}

.tariff-info__paragraph2 {
    display: flex;
  }
  
.tariff-info__paragraph2 > p {
    margin-top: 0px;
}

.tariff-info__paragraph2--literal {
    color: #737373;
    font-size: 10px;
    letter-spacing: 0.25px;
    line-height: 14px;
    margin-bottom: 32px;
}

.tariff-info__paragraph2--asterisk::before {
    color: #737373;
    content: '*';
    font-size: 10px;
    margin-right: 4px;
    vertical-align: top;
}    

/* Desktop */
@media screen and (min-width: 912px) {
    .tariff-info__card-title {
        text-align: center;
        font-size: 28px;
        line-height: 36px;
        margin: 0;
        margin-bottom: 32px;
    }

    .tariff-info__card-content {
        margin-bottom: 25px !important;
    }

    .tariff-info__card-content-param {
        display: grid;
        grid-template-columns: 1fr 2fr;
        row-gap: 1rem;
        align-items: center;
    }

    .tariff-info__paragraph2--literal {
        font-size: 12px;
        line-height: 18px;
    }

    .tariff-info__paragraph2--asterisk::before {
        font-size: 12px;
        margin-right: 7px;
    }    
}
