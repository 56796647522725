/* mobile first */
.insured-person__container {
  margin: auto 24px;
}

.birthdate-form__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 24px 24px auto;
}

/* desktop */
@media screen and (min-width: 912px) {
  .insured-person__radio-group {
    width: 568px;
    margin: 0 auto;
  }
}
