.checkbox-container {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border: 1px solid #aeaeae;
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 20px;
  margin-top: 22px;
}

.container.duw-disclosure h4 {
  margin: 0 0 10px;
}

.container.duw-disclosure {
  align-items: center;
}

.checkbox-inline-wrapper {
  flex-direction: row-reverse;
  justify-content: flex-end;
  height: auto;
}

.checkbox-container__error-message {
  color: #E80C26;
  font-size: 12px;
  margin-top: 0;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 4px 0 0 44px;
  text-align: left;
}

.ee_is-invalid {
  padding-bottom: 0 !important;
}

.checkbox-inline-wrapper .ee_checkbox {
  align-items: flex-start;
}

a { color: #8e0038 }

.duw-disclosure-intro {
  font-weight: bold;
}
