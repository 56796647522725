.consent-to-data-processing .intro__headline {
  font-size: 20px;
  line-height: 28px;
  hyphens: auto;
}

.consent-to-data-processing__section-subtitle {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px;
}

.consent-to-data-processing__section-0 {
  margin-bottom: 32px;
}

.consent-to-data-processing__section-1 {
  margin-bottom: 0;
}

.consent-to-data-processing__pdf-link,
.consent-to-data-processing__section .ee_checkbox {
  display: inline-flex;
  align-items: flex-start;
}

.consent-to-data-processing__pdf-icon {
  width: 18.4px;
  min-width: 18.4px;
  max-width: 18.4px;
  height: 21.4px;
  padding: 0 1.5px;
}

.consent-to-data-processing__pdf-label {
  padding: 0 1em 0 1.4em;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.consent-to-data-processing p.consent-to-data-processing__section-checkboxintro {
  margin-top: 16px;
  margin-bottom: 8px;
}

.consent-to-data-processing__section-1 label.ee_checkbox--regular.ee_checkbox:first-child {
  margin-bottom: 8px;
}

.consent-to-data-processing__section-1 label.ee_checkbox--regular.ee_checkbox {
  padding: 8px 0 0;
}

.consent-to-data-processing__section-1 span.ee_checkbox__box.ee_checkbox__box--regular {
  width: 18.6px;
  height: 18.6px;
  border-width: 1.4px;
  border-radius: 2px;
  padding-bottom: 0;
}

.consent-to-data-processing__label-group {
  margin-bottom: 8px;
}

.consent-to-data-processing__checkbox-label {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.additional__sentence {
  margin-top: 8px;
}

.consent-to-data-processing__error {
  width: 100%;
}

.consent-to-data-processing__error p.error-message {
  text-align: left;
  padding: 0 16px 0 41px;
}

@media screen and (min-width: 912px) {
  .consent-to-data-processing .intro__headline {
    font-size: 28px;
    line-height: 36px;
  }

  .consent-to-data-processing__section-subtitle {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;
  }

  .consent-to-data-processing__section-0 {
    margin-bottom: 48px;
  }

  .consent-to-data-processing__pdf-label {
    font-size: 16px;
    line-height: 24px;
  }

  .consent-to-data-processing p.consent-to-data-processing__section-checkboxintro {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .consent-to-data-processing__section-1 label.ee_checkbox--regular.ee_checkbox:first-child {
    margin-bottom: 16px;
  }
  .consent-to-data-processing__checkbox-label {
    font-size: 16px;
    line-height: 24px;
  }
}
