/* Mobile first */
.error-message {
  color: #e80c26;
  margin: 16px 0;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
  text-align: left;
}

@media screen and (min-width: 480px) {
  .error-message {
    text-align: center;
  }
}

.bold {
  font-weight: 700;
}

.esc_generated-content h4,
.esc_generated-content h5,
.esc_generated-content p {
  font-family: FS Me, Arial, Helvetica, sans-serif !important;
}

/* Virtual Assistant Fragments */
.va-with-fragment--no-va {
  margin-top: 48px;
}

.va-with-fragment .ee_message-with-avatar .container,
.va-with-fragment .ee_message-with-avatar .text,
.va-with-fragment .ee_message-with-avatar .aem-Grid {
  border: 0;
  margin: 0;
  padding: 0;
}
