/* mobile resolutions */
 .ee_circular-image {
  height: 48px !important;
  width: 48px !important;
}

.popover__text {
  align-self: left;
  color: #333333;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
  margin: 0px;
  padding: 8px 0px;
}

/* higher resolutions */
@media screen and (min-width: 912px) {
  .ee_circular-image {
    height: 64px !important;
    width: 64px !important;
  }
  
  .popover__text {
    padding: 12px 6px;
    font-size: 14px;
    line-height: 20px;
  }
}
