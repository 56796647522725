.kewe-section-annotation {
  padding-block: 64px 50px;
  display: flex;
  gap: 16px;
  color: #737373;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.kewe-clause {
  margin-block: 0;
}
