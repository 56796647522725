.accordion__title {
  font-size: 16px;
  line-height: 24px;

  /* TODO: Just to remove the margin by default */
  margin: 0;
}

.accordion__button {
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  margin: 0 0 8px;
  color: #8e0038;
  background: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.accordion__list {
  margin: 0 0 22px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.accordion__element-title {
  margin-bottom: 4px;
  font-weight: bold;
}

.accordion__element-description {
  margin: 0 0 12px;
}

.accordion__button svg {
  height: 18.19px;
  width: 18.08px;
}

/* TODO: Button can be reseted in a global file */
.duw-section-details__button {
  display: flex;
  align-items: center;
  border: none;
  margin: 0 0 8px;
  color: #8e0038;
  background: none;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.duw-section-details__icon {
  height: 18px;
  width: 18px;
}

@media screen and (min-width: 480px) {
  .accordion__title {
    font-size: 18px;
  }

  .accordion__list {
    display: grid;
    grid-template-columns: 272px 1fr;
    row-gap: 24px;
    margin: 24px 0 36px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .accordion__list--duw {
    display: grid;
    grid-template-columns: 7fr 150px;
    column-gap: 24px;
    row-gap: 24px;
  }

  .accordion__element-description {
    margin: 0;
  }

  .accordion__element-description--duw {
    text-align: center;
  }

  .accordion__button {
    margin-bottom: 16px;
  }

  .accordion__button svg {
    height: 21.83px;
    width: 21.7px;
  }
}
