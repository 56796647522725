.subscriptions-page__container {
  margin: auto 24px;
}

.subscriptions-page__error-container {
  color: #8e0038;
  font-size: 14px;
}

.subscriptions-page__error-message {
  position: relative;
  top: 5px;
}