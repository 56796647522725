/* mobile resolutions */
.container__loan-protection {
    margin: auto 24px;
}

.container__loan-protection--paragraph {
    font-size: 14px;
    line-height: 20px;
    margin: -16px auto 32px;
}

/* desktop resolutions */
@media screen and (min-width: 912px) {
    .container__loan-protection--paragraph {
        margin: -24px auto 48px;
        text-align: center;
    }

    .radio_groups--container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .radio-groups--tiles {
        width: 568px;
    }
}
