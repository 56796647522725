/* mobile first */
.slider-form__error-message {
  color: #e80c26;
  margin: 16px 0;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
}

/* desktop */
@media screen and (min-width: 480px) {
  .slider-form__error-message {
    text-align: center;
    margin: 24px 0;
  }
}
