/* mobile resolutions */
.state-bar {
  align-items: center;
  background-color: #efe7ed;
  display: flex;
  flex-direction: column;
}

.state-bar__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.state-bar-only-title {
  background-color: #efe7ed;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  line-height: 20px;
}

.state-bar__container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.state-bar__container-only-title {
  display: flex;
  justify-content: center;
}

.state-bar__container--title-only-title {
  font-weight: bold;
  margin: 16px auto;
}

.state-bar__title-only-title {
  font-size: 14px;
  line-height: 20px;
  margin: 16px auto;
}

.state-bar__title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 16px;
}

.state-bar__subline {
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
  margin-top: 4px;
  text-transform: capitalize;
}

.state-bar__price {
  margin: 12px 0 16px 0;
}

/* desktop resolutions */
@media screen and (min-width: 912px) {
  .state-bar {
    flex-direction: row;
    justify-content: space-between;
  }

  .state-bar__wrapper {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 864px;
  }

  .state-bar__container {
    align-items: flex-start;
  }

  .state-bar__title-only-title {
    font-size: 16px;
    line-height: 24px;
    margin: 20px auto;
  }

  .state-bar__title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }

  .state-bar__subline {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0px 20px 0px;
  }
}
