.progress-bar {
  height: 4px;
  background-color: #ffffff;

}

.progress-bar__indicator {
  height: 4px;
  border-radius: 0 2px 2px 0;
  background-color: #8e0038;
}
