.radio-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.radio-group--column {
  flex-direction: column;
}

@media screen and (min-width: 480px) {
  .radio-group {
    gap: 24px;
    flex-wrap: nowrap;
  }

  .radio-group--column {
    align-items: center;
  }
}

@media screen and (min-width: 912px) {
  .radio-group.radio-group--with-icons {
    flex-wrap: nowrap;
  }
}
