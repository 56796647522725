/* mobile first */
.updating-offer-form__container {
    margin: 0 auto 16px;
    width: 312px;
}

.updating-offer-form__label {
    padding-bottom: 4px;
}

.updating-offer-form__container .ee_form-row.ee_form-row--wide {
    margin: 0 !important;
}

.updating-offer-form__container .ee_form-row__item.ee_form-row__item--wide {
    margin: 0 !important;
}

/* desktop */
@media screen and (min-width: 768px) {
    .updating-offer-form__container {
        display: flex;
        margin-bottom: 0px;
        min-width: 568px;
    }

    .updating-offer-form__label {
        padding-top: 10px;
        width: 250px;
    }

    .updating-offer-form__input-field {
        display: flex;
        min-width: 320px;
        width: 422px;
    }

    .fee-page__updating-offer-form .ee_select {
        width: 346px !important;
    }

    .updating-offer-form__input-field .ee_input-wrapper {
        width: 346px !important;
    }

    .updating-offer-form__container.first-child .ee_form-row.ee_form-row--wide {
        display: flex;
    }

    .updating-offer-form__container .ee_form-row__item.ee_form-row__item--wide.ee_form-row__item--span1 {
       margin: 0px !important;
    }

    .updating-offer-form__container .ee_form-row__descriptor.ee_form-row__descriptor--wide {
        padding: 0 !important;
    }

    .updating-offer-form__container .ee_form-row.ee_form-row--wide {
        margin-bottom: 24px !important;
    }

    .fee-page__updating-offer-form .error-message {
        margin-top: 0;
    }
}

@media screen and (min-width: 912px) {
    .updating-offer-form__input-field {
        width: 320px;
    }
}
